<template>
  <div class="counselor-chat">
    <div class="chat-head">
      <div class="head-left">
        <el-image class="head-icon" :src="require(`../../../assets/image/interview.png`)" fit="contain"></el-image>
        <div class="head-title">AI职业咨询师</div>
      </div>
      <el-image class="head-right" @click="sendExit" :src="require(`../../../assets/image/exit2.png`)" fit="contain"></el-image>
    </div>
    <div class="chat-main">
      <div class="main-center">
        <div class="main-picture">
          <el-image class="img" v-show="isDynamic" :src="require(`../../../assets/image/counselor.gif`)" fit="contain"></el-image>
          <el-image class="img" v-show="!isDynamic" :src="require(`../../../assets/image/counselorstatic.jpg`)" fit="contain"></el-image>
        </div>
        <div class="main-content">
          <div class="content-top">
            <div class="answer-box f-e" v-if="messages">
              <div class="answer f-w">
                <span>{{ messages.question }}</span>
              </div>
              <i class="el-icon-user-solid icon-user"></i>
            </div>
            <div class="answer-box" v-if="messages">
              <el-image class="answer-image" :src="require(`../../../assets/image/logomini.png`)"></el-image>
              <div class="answer" v-if="messages.answer === '......'">
                <el-image class="answer-img" :src="require(`../../../assets/image/loading.gif`)" fit="fit"></el-image>
              </div>
              <div class="answer" v-else>
                <span>{{ messages.answer }}</span>
              </div>
            </div>
          </div>
          <div class="content-bottom">
            <el-input
              :autosize="{ minRows: 2, maxRows: 3 }"
              @keydown.native="handleKeyDown"
              type="textarea"
              placeholder="在此输入您想了解的内容，按Enter发送一个消息，Shift+Enter换行"
              v-model="result"
            ></el-input>
            <div class="bottom-button">
              <audio-player v-if="messages" ref="childRef" @child-event="handleChildEvent" :audioSrc="messages.question_video"></audio-player>
              <div v-if="voiceStatus">
                <el-image class="recognitionlisten" :src="require(`../../../assets/image/recognitionlisten.gif`)" fit="contain"></el-image>
              </div>
              <div v-if="chooseMicDeviceId !== ''">
                <el-image v-if="voiceStatus" @click="startRecognition" class="recognition" :src="require(`../../../assets/image/recognition.png`)" fit="contain"></el-image>
                <el-image v-else @click="startRecognition" class="recognition" :src="require(`../../../assets/image/recognition2.png`)" fit="contain"></el-image>
              </div>
              <el-button class="stopRecognition" @click="stopRecognition" size="medium" type="primary">发送</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AudioPlayer from '../../../components/AudioPlayer.vue'
import { insertChatCounselor, getSubtitle } from '../../../request/api'
import RecordRTC from 'recordrtc'

export default {
  components: {
    AudioPlayer
  },
  data() {
    return {
      isDynamic: false,
      result: '',
      isLoading: false,
      messages: null,
      audioSrc: '',

      voiceStatus: false,
      chooseMicDeviceId: '',
      stream: null,
      recorder: null
    }
  },
  methods: {
    sendExit () {
      this.$emit('update-value')
    },
    startRecognition() {
      if (this.voiceStatus) {
        this.stopRecord()
      } else {
        this.startRecord()
      }
    },
    // 结束录音
    stopRecord() {
      this.voiceStatus = false
      if (this.recorder !== null) {
        this.recorder.stopRecording(() => {
          this.sendData(this.recorder.getBlob())
          this.recorder = null
          this.stream.getAudioTracks().forEach(track => track.stop());
        })
      }
    },
    // 开始录音
    startRecord() {
      this.voiceStatus = true
      // 请求访问指定设备id的麦克风
      window.navigator.mediaDevices.getUserMedia({ audio: { deviceId: this.chooseMicDeviceId } }).then((stream) => {
        this.stream = stream

        // 创建一个音频录制器
        this.recorder = RecordRTC(stream, {
          type: 'audio',
          mimeType: 'audio/wav',
          recorderType: RecordRTC.StereoAudioRecorder,
          desiredSampRate: 16000,
          numberOfAudioChannels: 1, // 单声道
          // timeSlice: 5000,
          // ondataavailable: (blob) => this.sendData(blob),
        })
        this.recorder.startRecording()
      }).catch((err) => {
        this.voiceStatus = false
        this.$message.error('当前浏览器不支持麦克风', err)
      })
    },
    // 当有音频数据切片时调用
    async sendData(blob) {
      let formData = new FormData()
      formData.append('file', blob, 'file.wav')

      try {
        const res = await getSubtitle(formData)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
          return
        }
        if (res.data.data !== '') {
          this.result = this.result + res.data.data
        }
      } catch(err) {
        this.$message.error('语音翻译失败', err)
      }
    },
    // 获取设备信息
    getMic() {
      const that = this
      // 检查浏览器是否支持媒体设备API，是否支持获取媒体流的方法
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        // 请求访问用户麦克风权限
        navigator.mediaDevices.getUserMedia({audio: true}).then((stream) => {
          // 用户同意，获取一个包含音频的对象

          // 列出所有媒体设备
          navigator.mediaDevices.enumerateDevices().then(function (devices) {
            devices.forEach(function (device) {
              // 选择设备是音频输入设备
              if (device.kind === 'audioinput') {
                // 并且设备ID不是默认的，也不是通信用的
                if (device.deviceId !== 'default' && device.deviceId !== 'communications') {
                  that.chooseMicDeviceId = device.deviceId
                }
              }
            })
          })

          // 停止所有音频轨道
          stream.getTracks().forEach(track => track.stop())
        })
      }
    },


    // 识别多行文本是否提交
    handleKeyDown (event) {
      if (!event.shiftKey && event.keyCode === 13) {
        this.stopRecognition()
        this.result = ''
      }
    },
    async stopRecognition() {
      if (this.result.trim() === '' ) return
      if (this.isLoading) return

      this.stopRecord()
      this.isLoading = true
      this.messages = {
        answer: '......',
        question: this.result,
        question_video: ''
      }
      try {
        let postData = {
          'question': this.result
        }
        this.result = ''
        const res = await insertChatCounselor(postData)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
          return
        }

        const mes = res.data.data
        this.messages = {
          answer: mes.answer,
          question: mes.question,
          question_video: 'https://' + mes.question_video
        }
        this.startAnswering()

        this.isLoading = false
      } finally {
        this.isLoading = false
      }
    },
    handleChildEvent() {
      this.isDynamic = false
    },
    startAnswering() {
      this.isDynamic = true
      if (this.$refs.childRef) {
        this.$refs.childRef.togglePlay()
      }
    }
  },
  mounted() {
    this.getMic()
  }
}
</script>

<style lang="less" scoped>
.counselor-chat {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .chat-main {
    height: 92%;
    display: flex;
    align-items: center;
    justify-content: center;
    .main-center {
      display: flex;
      .main-picture {
        margin-right: 20px;
        .img {
          height: 71vh;
        }
      }
      .main-content {
        height: 69vh;
        width: 20vw;
        padding: 10px;
        border: 1px solid #7eacff;
        border-radius: 10px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        .content-top {
          height: 54vh;
          border-bottom: 1px solid #7a7c7e;
          display: flex;
          flex-direction: column;
          overflow: auto;
          .answer-box {
            display: flex;
            align-items: flex-start;
            margin: 4px 0;
            .icon-user {
              font-size: 28px;
              line-height: 28px;
              color: #2a6ee9;
            }
            .answer-image {
              width: 36px;
              height: auto;
              flex-shrink: 0;
              flex-grow: 0;
              margin-right: 4px;
            }
            .answer {
              color: #555;
              font-size: 14px;
              line-height: 28px;
              white-space: pre-wrap;
              .answer-img {
                height: 40px;
              }
            }
            .f-w {
              font-weight: bold;
            }
          }
          .f-e {
            justify-content: flex-end;
          }
        }
        .content-bottom {
          display: flex;
          flex-direction: column;
          flex: 1;
          padding: 8px;
          .bottom-button {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 8px;
            .recognitionlisten {
              height: 40px;
            }
            .recognition {
              height: 40px;
              cursor: pointer;
            }
            .stopRecognition {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
  .chat-head {
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .head-left {
      display: flex;
      align-items: center;
      .head-icon {
        width: 40px;
        margin-right: 6px;
      }
      .head-title {
        font-size: 14px;
        color: #7a7c7e;
      }
    }
    .head-right {
      padding-right: 1%;
      width: 70px;
      cursor: pointer;
    }
  }
}
</style>