<template>
  <div class="audio-player">
    <audio ref="audio" :src="audioSrc" @ended="onEnded" @error="onError"></audio>
  </div>
</template>

<script>
  export default {
    name: 'AudioPlayer',
    props: {
      audioSrc: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        isPlaying: false
      };
    },
    watch: {
      audioSrc(newVal) {
        this.stopAudio()
        if (this.isPlaying) {
          this.loadAndPlayAudio(newVal)
        }
      }
    },
    beforeDestroy() {
      this.stopAudio();
      this.$refs.audio.removeEventListener('loadedmetadata', this.handleLoadedMetadata)
      this.$refs.audio.removeEventListener('ended', this.onEnded);
      this.$refs.audio.removeEventListener('error', this.onError);
    },
    mounted() {
      this.$refs.audio.addEventListener('loadedmetadata', this.handleLoadedMetadata)
    },
    methods: {
      loadAndPlayAudio(src) {
        this.$refs.audio.src = src;
        this.isPlaying = true
      },
      togglePlay() {
        const audio = this.$refs.audio;
        if (audio.paused) {
          this.playAudio();
        } else {
          audio.pause()
        }
      },
      playAudio() {
        const audio = this.$refs.audio
        if (audio.readyState >= 4) {
          audio.play()
            .then(() => {
              this.isPlaying = true
            })
            .catch(error => {
              this.$message.error('音频播放出错！：', error)
            })
        } else {
          // 音频没有准备好
          setTimeout(() => this.playAudio(), 100)
        }
      },
      onEnded() {
        this.stopAudio()
        this.$emit('child-event')
      },
      onError() {
        this.stopAudio()
        this.$emit('child-event')
        // this.$message.error('音频播放出错！')
      },
      stopAudio() {
        const audio = this.$refs.audio;
        if (audio) {
          audio.pause();
          audio.currentTime = 0;
          this.isPlaying = false
        }
      },
    },
  };
</script>

<style>

</style>